<app-loader *ngIf="cargando"></app-loader>
<app-breadcrumb [title]="'Settings' | translate" [items]="['Maestros' | translate]"
  [active_item]="'Settings' | translate">
</app-breadcrumb>
<div class="container-fluid">
  <div class="edit-profile">
    <div class="row">
      <div class="col-sm-12 xl-100">
        <div class="card">
          <div class="card-body">
            <i class="icon-bg" width="80" height="80"
              style="opacity: 0.4;position: absolute; color: #6ec0d4; top: 10px; left: 10px;"
              data-feather="settings"></i>
            <div class="row mb-2">
              <div class="col">
                <h3 class="mb-1">{{'Configuración de la aplicación'}}</h3>
                <p class="mb-1">{{'Ajustes de control del comportamiento de las opciones para los usuarios.'}}</p>
              </div>
              <div class="col-auto">
                <!-- <label for="photoprofile" class="subir mt-3">
                  {{'Change profile picture' | translate}}
                </label>
                <input type="file" #subirfoto (change)="uploadImage()" id="photoprofile" style='display: none;' /> -->
              </div>
            </div>
            <hr><br>

            <div class="row">

              <!-- HORARIOS DE ENVIO -->
              <div class="card col-sm-12 col-md-5 p-5 m-5">
                <h5 style="align-content: center;">{{'Closed Hours' | translate}}</h5>
                <p>{{'Define the closed time for orders' | translate}}</p>

                <div class="input-group mt-4 mb-2" style="align-items: baseline;">
                  <p> Creación de pedidos AM hasta las:</p>
                  <input #AM type="time" class="form-control ml-4 mt-0"
                    style="min-width: 200px;max-width: 200px; text-align: center;" (change)="save()"
                    placeholder="00:00" />
                </div>

                <div class="input-group mt-2 mb-4" style="align-items: baseline;">
                  <p> Creacion de pedidos PM hasta las:</p>
                  <input #PM type="time" class="form-control ml-4"
                    style="min-width: 200px;max-width: 200px; text-align: center;" (change)="save()"
                    placeholder="00:00">
                </div>
              </div>

              <!-- DIAS PERMITIDOS -->
              <div class="card col-sm-12 col-md-5 p-5 m-5">
                <h5 style="align-content: center;">{{'Allow days (Sundays)' | translate}}</h5>
                <p>{{'Adiciona los dias domingos de trabajo normal' | translate}}</p>
                <div>
                  <!-- <h5>Días adicionados</h5> -->
                  <div class="row">
                    <div class="col-xs-12 col-md-12">
                      <div class="input-group">
                        <input type="date" class="form-control mr-2" placeholder="enter available days"
                          [(ngModel)]="dateBlock" />
                        <div class="input-group-btn">
                          <button class="btn btn-primary" type="button" (click)="addDateAllow(dateBlock)">
                            Adicionar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul class="list-group mt-4 mb-4">
                    <li *ngFor="let item of permitidos" class="list-group-item">
                      <span><i placement="top" ngbTooltip="{{'Delete' | translate}}"
                          style="font-size: 21px; margin-left: 10px; color:red; cursor: pointer;"
                          class="icofont icofont-trash" (click)="removeDateAllow(item)"></i></span>
                      <span class="ml-5"> {{ item.fecha }} </span>
                    </li>
                  </ul>

                </div>
                <div class="col-xs-12 col-md-12 mb-5">
                  <button class="btn btn-primary" style="width: 60%; margin: 0 20%;" type="button" (click)="save()">
                    <span class="glyphicon glyphicon-ok">Guardar</span>
                  </button>
                </div>
              </div>

              <!-- DIAS BLOQUEADOS -->
              <div class="card col-sm-12 col-md-5 p-5 m-5">
                <h5 style="align-content: center;">{{'Blocked days' | translate}}</h5>
                <p>{{'Adiciona los dias bloqueados para grabar envíos.' | translate}}</p>
                <div>
                  <!-- <h5>Días adicionados</h5> -->
                  <div class="row">
                    <div class="col-xs-12 col-md-12">
                      <div class="input-group">
                        <input type="date" class="form-control mr-2" placeholder="enter blocked days"
                          [(ngModel)]="dateAllow" />
                        <div class="input-group-btn">
                          <button class="btn btn-primary" type="button" (click)="addDateBlock(dateAllow)">
                            <span class="glyphicon glyphicon-ok">Adicionar</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="mt-4 mb-4">
                      <span *ngFor="let item of bloqueados" class="badge badge-warning m-1"
                        style="font-size: 14px; font-weight: 300;">{{item.fecha}}
                        <span><i placement="top" ngbTooltip="{{'Delete' | translate}}"
                            style="font-size: 17px; margin-left: 10px; color:red; cursor: pointer;"
                            class="icofont icofont-trash" (click)="removeDateBlock(item)"></i></span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 mb-5">
                  <button class="btn btn-primary" style="width: 60%; margin: 0 20%;" type="button" (click)="save()">
                    <span class="glyphicon glyphicon-ok">Guardar</span>
                  </button>
                </div>
              </div>

              <!-- NOTIFICACIONES -->
              <div class="card col-sm-12 col-md-5 p-5 m-5">
                <h5 style="align-content: center;">{{'Notificaciones' | translate}}</h5>
                <p>{{'Programa las notificaciones.' | translate}}</p>
                <div>
                  <div class="row">
                    <div class="col-xs-12 col-md-12">
                      <div class="input-group">
                        <input type="date" class="form-control mr-2" placeholder="enter blocked days"
                          [(ngModel)]="dateNotif" />
                        <textarea type="text" class="form-control mr-2" placeholder="Texto para notificación"
                          [(ngModel)]="textNotif"></textarea>
                        <div class="input-group-btn">
                          <button class="btn btn-primary" type="button" (click)="addDateNotif(dateNotif, textNotif)">
                            <span class="glyphicon glyphicon-ok">Adicionar</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul class="list-group mt-4 mb-4">
                    <li *ngFor="let item of notificaci" class="list-group-item">
                      <span><i placement="top" ngbTooltip="{{'Delete' | translate}}"
                          style="font-size: 21px; margin-left: 10px; color:red; cursor: pointer;"
                          class="icofont icofont-trash" (click)="removeDateNotif(item)"></i></span>
                      <span class="ml-5"> {{ item.fecha }} </span>
                      <span class="ml-5"> {{ item.texto }} </span>
                    </li>
                  </ul>
                </div>
                <div class="col-xs-12 col-md-12 mb-5">
                  <button class="btn btn-primary" style="width: 60%; margin: 0 20%;" type="button" (click)="save()">
                    <span class="glyphicon glyphicon-ok">Guardar</span>
                  </button>
                </div>
              </div>

              <!-- NOTAS -->
              <div class="card col-sm-12 col-md-5 p-5 m-5">
                <h5 style="align-content: center;">{{'Notas' | translate}}</h5>
                <p>{{'Notas para la creación de envíos.' | translate}}</p>
                <div>
                  <div class="row">
                    <div class="col-xs-12 col-md-12">
                      <div class="input-group">
                        <input type="text" class="form-control mr-2" placeholder="Tìtulo" [(ngModel)]="titleNota" />
                        <textarea type="text" class="form-control mr-2" placeholder="Texto en la nota"
                          [(ngModel)]="textNota"></textarea>
                        <div class="input-group-btn">
                          <button class="btn btn-primary" type="button" (click)="addNota(titleNota, textNota)">
                            <span class="glyphicon glyphicon-ok">Adicionar</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul class="list-group mt-4 mb-4">
                    <li *ngFor="let item of notas" class="list-group-item">
                      <span><i placement="top" ngbTooltip="{{'Delete' | translate}}"
                          style="font-size: 21px; margin-left: 10px; color:red; cursor: pointer;"
                          class="icofont icofont-trash" (click)="removeNota(item)"></i></span>
                      <span class="ml-5"> {{ item.title }} </span>
                      <span class="ml-5"> {{ item.texto }} </span>
                    </li>
                  </ul>
                </div>
                <div class="col-xs-12 col-md-12 mb-5">
                  <button class="btn btn-primary" style="width: 60%; margin: 0 20%;" type="button" (click)="save()">
                    <span class="glyphicon glyphicon-ok">Guardar</span>
                  </button>
                </div>
              </div>

              <!-- CONSECUTIVOS-->
              <div class="card col-sm-12 col-md-5 p-5 m-5">
                <h5 style="align-content: center;">{{'Consecutivos ALEGRA' | translate}}</h5>
                <p>{{'Defina el consecutivo para cada empresa' | translate}}</p>

                <div class="input-group mt-4 mb-2" style="align-items: baseline;">
                  <p>UNDERGOLD</p>
                  <input #consecutivo1 type="text" class="form-control ml-4 mt-0"
                    style="min-width: 200px;max-width: 200px; text-align: right;"/>
                </div>

                <div class="input-group mt-2 mb-4" style="align-items: baseline;">
                  <p>ONCE PIJAMAS</p>
                  <input #consecutivo2 type="text" class="form-control ml-4"
                    style="min-width: 200px;max-width: 200px; text-align: right;">
                </div>
                <div class="col-xs-12 col-md-12 mb-5">
                  <button class="btn btn-primary" style="width: 60%; margin: 0 20%;" type="button" (click)="save()">
                    <span class="glyphicon glyphicon-ok">Guardar</span>
                  </button>
                </div>
              </div>


              <!-- ROTULO -->
              <div class="card col-sm-12 col-md-5 p-5 m-5">
                <h5 style="align-content: center;">{{'Rótulo' | translate}}</h5>
                <p>{{'Medidas para el rótulo (en milímetros).' | translate}}</p>
                <div>
                  <div class="row">
                    <div class="col-xs-12 mt-4 col-md-12">
                      <p>Medidas de página.</p>
                      <div class="input-group">
                        <input type="text" class="form-control mr-2" placeholder="Ancho" #width />
                        <input type="text" class="form-control mr-2" placeholder="Largo" #height />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12 mt-2 col-md-12">
                      <p>Márgenes de página.</p>
                      <div class="input-group">
                        <input type="text" class="form-control mr-2" placeholder="Superior" #top />
                        <input type="text" class="form-control mr-2" placeholder="Izquierda" #left />
                        <input type="text" class="form-control mr-2" placeholder="Derecha" #right />
                        <input type="text" class="form-control mr-2" placeholder="Inferior" #bottom />
                      </div>
                    </div>
                  </div>
                  <div class="input-group-btn mt-4 text-center">
                    <button class="btn btn-primary" type="button" (click)="pageSizes()">
                      <span class="glyphicon glyphicon-ok">Guardar</span>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>