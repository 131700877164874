import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';
import { ServiciosService } from '../services/servicios.service';

@Injectable({
    providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {

    userData: any;
    userRol: any;

    constructor(private authService: AuthService,
        private router: Router,
        private services: ServiciosService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let user = JSON.parse(localStorage.getItem('userMce'));

        if (user.nit !== '1') {
            // window.alert("You are not allowed to access this URL!");
            this.router.navigate(['/default'])
        }

        // if (user?.rol === 'Operario') {
        //     this.services.getRol({"rol": 'Operario'}).then(res => {

        //         if (state.url === '/request/create-request' && !res[0].crear_pedido) {
        //             this.router.navigate(['/default']);
        //         }
                
        //         if (state.url === '/requests/browse-request' && !res[0].consulta_pedidos) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/picking') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/packing') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/dispatch') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/create-order' && !res[0].envios) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/browse-order' && !res[0].consulta_envios) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/quotation' && !res[0].cotizacion) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/pay-order') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/co2total') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/empresas') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/security/roles') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/usuarios') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/cobertura') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/ajustes') {
        //             this.router.navigate(['/default']);
        //         }

        //         return true

        //     });
        // }

        // if (user?.rol === 'Operario Packing') {
        //     this.services.getRol({"rol": 'Operario Packing'}).then(res => {

        //         if (state.url === '/requests/create-request' && !res[0].crear_pedido) {
        //             this.router.navigate(['/default']);
        //         }
                
        //         if (state.url === '/requests/browse-request' && !res[0].consulta_pedidos) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/picking') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/packing') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/dispatch') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/create-order' && !res[0].envios) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/browse-order' && !res[0].consulta_envios) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/quotation' && !res[0].cotizacion) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/pay-order') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/co2total') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/empresas') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/security/roles') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/usuarios') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/cobertura') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/ajustes') {
        //             this.router.navigate(['/default']);
        //         }

        //         return true

        //     });
        // }

        // if (user?.rol === 'Administrador Empresa') {
        //     this.services.getRol({"rol": 'Administrador Empresa'}).then(res => {

        //         if (state.url === '/request/create-request' && !res[0].crear_pedido) {
        //             this.router.navigate(['/default']);
        //         }
                
        //         if (state.url === '/requests/browse-request' && !res[0].consulta_pedidos) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/picking') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/packing') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/requests/dispatch') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/create-order' && !res[0].envios) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/browse-order' && !res[0].consulta_envios) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/quotation' && !res[0].cotizacion) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/pay-order') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/orders/co2total') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/empresas') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/security/roles') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/usuarios' && !res[0].asignar_roles) {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/cobertura') {
        //             this.router.navigate(['/default']);
        //         }

        //         if (state.url === '/maestros/ajustes') {
        //             this.router.navigate(['/default']);
        //         }

        //         return true

        //     });
        // }

        // if (user?.rol === 'Administrador Aplicacion' && user.nit === '1') {
        //     return true
        // }

        // if (!user?.rol && user.nit === '1') {
        //     return true
        // }

        // if (!user?.rol && user.nit !== '1') {

        //     if (state.url === '/request/create-request') {
        //         this.router.navigate(['/default']);
        //     }
            
        //     if (state.url === '/requests/browse-request') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/requests/picking') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/requests/packing') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/requests/dispatch') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/orders/co2total') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/orders/pay-order') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/maestros/usuarios') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/maestros/empresas') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/maestros/ajustes') {
        //         this.router.navigate(['/default']);
        //     }

        //     if (state.url === '/maestros/cobertura') {
        //         this.router.navigate(['/default']);
        //     }


        // }
        
        return true

        // if (user.rol !== 'Administrador Empresa') {
            
        //     // window.alert("You are not allowed to access this URL!");
        //     this.router.navigate(['/default'])
        // } else if (user.nit !== '1') {
        //     this.router.navigate(['/default'])
        // }
    }

    // canActivateChild(next: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot): boolean | UrlTree {
        
    //     let user = JSON.parse(localStorage.getItem('userMce'));
    //     console.log(state, next)
    //     this.services.getRol({"rol": 'Operario'}).then(res => {
    //        debugger; if (user.rol === 'Operario') {
    //             if (state.url === '/orders/create-order' && !res[0].envios) {
    //                 this.router.navigate(['/default']);
    //             }
    //             if (state.url === '/orders/browse-order' && !res[0].consulta_envios) {
    //                 this.router.navigate(['/default']);
    //             }
    //             return true
    //         }
    //         return true
    //     })
    //     return true  
    // }


}