import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() items;
  @Input() qty

  public openNotification: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

}
