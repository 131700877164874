import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../shared/services/firebase/auth.service';
import { ServiciosService } from '../shared/services/servicios.service';
import { UtilsService } from '../shared/services/utils.service';
import { NgbModal, NgbModalConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RestoreModalComponent } from "./restoreModal";
import Swal from 'sweetalert2';
declare var grecaptcha: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  version = '';
  user: any ={};
  permissions: any;

  constructor(
    public authService: AuthService,
    private service: ServiciosService,
    private utils: UtilsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {

    this.version = this.service.version;
  }

  showPassword() {
    this.show = !this.show;
  }

  // Login With Google
  loginGoogle() {
    // this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
    // this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
    // this.authService.signInFacebok();
  }

  // // Simple Login
  // login1(data) {
  //   this.authService.SignIn(this.loginForm.value['email'].toLowerCase(), this.utils.hash(this.loginForm.value['password']));
  // }

  // onSubmit() {
  //   this.authService.SignIn(this.loginForm.value['email'].toLowerCase(), this.utils.hash(this.loginForm.value['password']));
  // }

  login(data) {

    this.user = {};
    this.user.email = this.loginForm.value['email'].toLowerCase();
    this.user.password = this.utils.hash(this.loginForm.value['password']);
    this.user.token = null;
    // console.log(this.user.email)
    // this.service.getUsuario({"email": this.user.email}).then(res => {
    //   console.log(res)
    //   if (res[0].rol) {
    //     this.service.getRol({"rol": res[0].rol}).then(data => {
    //       this.permissions = data[0];
    //       console.log(this.permissions)
    //     });
    //   }

    // })
    

    grecaptcha.ready(() => {
      grecaptcha.execute('6LcmmdEaAAAAAHFxXohUYnyQtW6usQ7wm_cuIg52').then((token) => {
        this.user.token = token;
        this.authService.SignIn(this.loginForm.value['email'].toLowerCase(), this.utils.hash(this.loginForm.value['password']), token);
        // this.userService.login(this.user).subscribe((authentication: any) => {
        //   localStorage.setItem('token', authentication.token)
        //   this.cookieService.put('token', authentication.token)
        //   sessionStorage.setItem('bid', authentication.bid)
        //   /*Use userService to notifiy if user has logged in*/
        //   /*this.userService.isLoggedIn = true;*/
        //   this.userService.isLoggedIn.next(true)
        //   this.router.navigate(['/search'])
        // }, ({ error }) => {
        //   if (error.status && error.data && error.status === 'totp_token_requried') {
        //     localStorage.setItem('totp_tmp_token', error.data.tmpToken)
        //     this.router.navigate(['/2fa/enter'])
        //     return
        //   }

        //   console.log(error)

        //   localStorage.removeItem('token')
        //   this.cookieService.remove('token', { domain: document.domain })
        //   sessionStorage.removeItem('bid')
        //   this.error = error
        //   /* Use userService to notify user failed to log in */
        //   /*this.userServe.isLoggedIn = false;*/
        //   this.userService.isLoggedIn.next(false)
        //   this.emailControl.markAsPristine()
        //   this.passwordControl.markAsPristine()
        // })
      });
    });

    // if (this.rememberMe.value) {
    //   localStorage.setItem('email', this.user.email)
    // } else {
    //   localStorage.removeItem('email')
    // }

    // if (this.error && this.user.email && this.user.email.match(/support@.*/)) {
    //   console.log('@echipa de suport: Secretul nostru comun este încă Caoimhe cu parola de master gol!')
    // }

  }

  // restore() {
  //   const config: NgbModalOptions = {
  //     backdrop: "static",
  //     keyboard: false,
  //     centered: true
  //   }

  //   const modalRef = this.modalService.open(RestoreModalComponent, config);

  //   modalRef.result.then(async (result) => {
  //     if (result === 'restored') {
  //       setTimeout(async () => {
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'Email enviado !',
  //           text: 'Por favor revisa tu bandeja de entrada o la carpeta Spam.',
  //           showConfirmButton: true
  //         })
  //       }, 200);
  //     }
  //   }, (reason) => {
  //     // this.closeResult = Dismissed ${this.getDismissReason(reason)};
  //   });
  // }


}
