import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiciosService } from 'src/app/shared/services/servicios.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  // type = '';
  name = '';
  img = '';

  constructor(
    public service: ServiciosService,
    private router: Router

  ) { }

  ngOnInit() {

    const res: any = JSON.parse(localStorage.getItem('userMce'));
    this.name = res.name.split(' ')[0] + ' ' + res.name.split(' ')[1];
    this.img = res.image;
  }

  goToProfile() {
    this.router.navigate(['/maestros/edit-profile']);
  }

}
