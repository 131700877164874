import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { ServiciosService } from '../../services/servicios.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
// import * as io from 'socket.io-client';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // private socket: any;
  // public data: any;

  public elem: any;
  public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' ? true : false;

  idUsuario = '';
  name = '';
  nit = '';
  empresas = [];
  notificaci = [];
  notifiSelected = [];
  numberNotif = 0;
  empresa = '';
  url = 'https://wa.me/573218327617?text=Hola%ENVIOS%20%21%20Te%20escribo%20desde%20PCE%20';

  studentsObservable;

  constructor(public layout: LayoutService,
    public navServices: NavService,
    private services: ServiciosService,
    public DomSanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  async ngOnInit() {

    // this.services.getNofications().subscribe((data) => {
    //   this.actualizarNotificaciones();
    // });

    // this.studentsObservable = this.getNot();
    // this.studentsObservable.subscribe((studentsData) => {
    //   this.actualizarNotificaciones();
    // });

    // this.socket = io('http://localhost:5001/apitransmovee/us-central1/api:8080');

    this.elem = document.documentElement;
    const res: any = await JSON.parse(localStorage.getItem('userMce'));
    this.idUsuario = res.email;
    this.name = res.name.split(' ')[0] + ' ' + res.name.split(' ')[1];

    this.nit = res.nit;

    await this.services.getActiveCompanies().then(async res => {
      this.empresas = await res;

      const ind = this.empresas.findIndex(x => x.nit === this.nit);
      if (ind !== -1)
        this.empresa = this.empresas[ind].nombre;
      this.url = 'https://wa.me/573187410927?text=Hola%ENVIOS%20%21%20Te%20escribo%20desde%20PCE.%20Soy%20' + this.name + '%20de%20' + this.empresa + '%20';
    });

    // 573218327617
    // Traer tema
    this.dark = res.theme;
    this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';

    this.actualizarNotificaciones();

    // this.socket.on('notification', data => {
    //   this.data = data;
    //   this.actualizarNotificaciones();
    // });

  }

  async getNot() {

    const studentsObservable = new Observable(observer => {
      const intervalo = setInterval(async () => {
        // observer.next();
        if (this.studentsObservable === null) {
          clearInterval(intervalo)
        }
        await this.actualizarNotificaciones();
      }, 6000000);
    });
    return studentsObservable;
  }

  ngOnDestroy() {
    // this.studentsObservable.unsubscribe();
    this.studentsObservable = null;
  }

  async actualizarNotificaciones() {

    // Notificaciones

    if (this.nit !== '1') {
      await this.services.getSettings().then(async res => {

        const data = await res;
        if (data[0] !== undefined) {

          this.notificaci = data[0].notificaci || [];


          // verificar notificaciones actuales y futuras
          const hoy = new Date();
          hoy.setHours(hoy.getHours() - 5);
          // const fechaHoy = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate();

          this.numberNotif = 0;
          this.notifiSelected = [];

          this.notificaci.forEach(element => {
            const fec = new Date(element.fecha);
            if (hoy <= fec) {
              this.numberNotif += 1;
              this.notifiSelected.push(element);
            }
          });
        }
      });

    } else {
      await this.services.readRequestNote().then(res => {
        const data: [] = res;

        if (data !== undefined) {
          this.numberNotif = data.length;
  
          data.forEach((element: any) => {
            this.notifiSelected.push({
              "fecha": element.nota.fecha.slice(0, 10),
              "texto": 'Pedido ' + element.pedido + ': ' + element.nota.nota
            });
          });
        }
      });

    }
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    // this.navServices.megaMenu = false;
    // this.navServices.levelMenu = false;
  }

  async layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';

    // actualizar tema
    const item = {
      email: this.idUsuario,
      emailAnt: this.idUsuario,
      tema: this.dark,
      settings: '1'
    }
    await this.services.editUsuario(item).then(async res => {
      const data = await res;
      const res2: any = JSON.parse(localStorage.getItem('userMce'));
      res2.theme = this.dark;
      localStorage.setItem('userMce', JSON.stringify(res2));
    });

  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }



  }

}
