import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ServiciosService } from './servicios.service';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// // Mega Menu
	// public megaMenu: boolean = false;
	// public levelMenu: boolean = false;
	// public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	//TI
	empresa = '';
	esCart = false;
	esStock = false;
	user: any;
	userRol: any;
	findUser: any[]


	// MENU
	MENUITEMS1: Menu[] = [];
	MENUITEMSOP: Menu[] = [];
	MENUITEMSOPPACK: Menu[] = [];
	MENUITEMSNOROL: Menu[] = [];

	constructor(
		private router: Router,
		private services: ServiciosService) {

		// console.log(this.user.rol)

		// if (this.user.rol) {
		// 	this.services.getRol({"rol": this.user?.rol}).then(data => {
		// 		this.userRol = data[0];
		// 		// console.log(this.userRol)

		// 	});
		// }

		// if (this.user.rol === 'Administrador Empresa') {
		// 	this.crearMenu();
		// 	this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS1);

		// } else if (this.user.rol === 'Operario') {
		// 	this.crearMenuOperario();
		// 	this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSOP);

		// } else if (this.user.rol === 'Operario Packing') {
		// 	this.crearMenuOperarioPacking();
		// 	this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSOPPACK);

		// } else if (this.empresa === '1' && this.user.rol === 'Administrador Aplicacion') {
		// 	this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSA);

		// } else if (this.empresa === '1') {
		// 	this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSA);

		// } else if (this.empresa !== '1') {
		// 	this.crearMenuNoRol();
		// 	this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSNOROL);
		// }

		//TODODMG: Coinsultar si es cart
		this.esCart = false;

		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				// this.megaMenu = false;
				// this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				// this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				// this.megaMenu = false;
				// this.levelMenu = false;
			});
		}
	}

	getMenuItems() {
		this.items = null;

		// empresas
		const res: any = JSON.parse(localStorage.getItem('userMce'));
		this.empresa = res.nit;
		this.user = res;

		if (this.empresa !== '1') {
			this.crearMenu();
			this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS1);
		} else {
			this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSA);
		}
	}


	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	//******************************************* Administrador Empresa ************************************************

	async crearMenu() {

		this.MENUITEMS1 = [];

		const data = {
			_id: this.empresa
		}
		await this.services.getCompany(data).then(res => {
			this.esCart = res[0].cart !== undefined ? res[0].cart : false;
			this.esStock = res[0].stock !== undefined ? res[0].stock : false;
		});

		// LLENAR MENU GENERAL:
		//****************** PEDIDOS ****************************** */

		// if (await this.userRol.crear_pedido || await this.userRol.consulta_pedidos) {

		const pedidos = {
			title: 'Pedidos', icon: 'tag', type: 'sub', badgeType: 'danger', children: [
				{ path: '/requests/analitycs', title: 'Analítica', type: 'link' },
				{ path: '/requests/create-request', title: 'Crear pedido', type: 'link' },
				{ path: '/requests/browse-request', title: 'Browse by Dates', type: 'link' },
				// { path: '/requests/diasiniva', title: 'Día sin IVA', type: 'link' }
				// { path: '/requests/dispatch', title: 'Despachos', type: 'link' }
			]
		}

		// if (this.userRol.crear_pedido) {
		// }	
		// if (this.userRol.consulta_pedidos) {
		// }

		// if (this.userRol.picking) {
		// 	pedidos.children.push({ path: '/requests/picking', title: 'Picking', type: 'link' });
		// }

		// if (this.userRol.packing) {
		// 	pedidos.children.push({ path: '/requests/packing', title: 'Picking', type: 'link' });
		// }

		// if (this.userRol.dispatch) {
		// }

		this.MENUITEMS1.push(pedidos);
		// }
		//******************************************************** */
		//****************** ENVIOS ****************************** */

		// if (this.userRol.envios || this.userRol.consulta_envios || this.userRol.cotizacion) {

		const envios = {
			title: 'Orders', icon: 'truck', type: 'sub', badgeType: 'success', children: [
				// { path: '/orders/analitycs', title: 'Analítica', type: 'link' }
				{ path: '/orders/create-order', title: 'Create', type: 'link' },
				{ path: '/orders/browse-order', title: 'Browse by Dates', type: 'link' },
				// { path: '/orders/quotation', title: 'Cotización', type: 'link'}
			]
		}

		// if (this.userRol.envios) {

		// }

		// if (this.userRol.consulta_envios) {
		// }

		// if (this.userRol.cotizacion) {
		// }

		this.MENUITEMS1.push(envios);
		// }
		//******************************************************** */
		//****************** STOCK Y MAESTROS ******************** */

		if (this.esStock) {
			const stock = {
				title: 'Stock', icon: 'package', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', children: [
					{ path: '/stock/analitycs', title: 'Analítica', type: 'link' },
					{ path: '/stock/inventory', title: 'Inventario', type: 'link' },
					{ path: '/stock/backOrder', title: 'Back Order', type: 'link' },
					// { path: '/stock/reception', title: 'Recepcion de Mercancia', type: 'link' },
					{ path: '/stock/adjustment', title: 'Movimientos de Productos', type: 'link' },
					{ path: '/stock/movements', title: 'Historial', type: 'link' },
					// { path: '/stock/tagsGenerator', title: 'Generador de etiquetas', type: 'link' },
					// { path: '/stock/physicalInventory', title: 'Inventario Fisico', type: 'link' },
				]
			}

			this.MENUITEMS1.push(stock);
		}

		const maestros = {
			title: 'Masters',
			icon: 'box',
			type: 'sub',
			active: false,
			children: [
				{ path: '/maestros/edit-profile', title: 'Profile', type: 'link' },
				{ path: '/maestros/direcciones', title: 'Addresses', type: 'link' }
			]
		}

		// if (this.userRol.asignar_roles) {
		// 	maestros.children.push({ path: '/maestros/usuarios', title: 'Users', type: 'link' });
		// }

		if (this.esCart || this.esStock) {
			// 	// maestros.children.push({ path: '/cart/analitycs', title: 'Analítica Cart', type: 'link' });
			maestros.children.push({ path: '/cart/productos', title: 'Products', type: 'link' });
			maestros.children.push({ path: '/cart/imagenes', title: 'Products images', type: 'link' });
		}

		if (this.esStock) {
			// maestros.children.push({ path: '/cart/productos', title: 'Products', type: 'link' });
			// maestros.children.push({ path: '/cart/imagenes', title: 'Products images', type: 'link' });
			maestros.children.push({ path: '/stock/bodegas', title: 'Bodegas', type: 'link' });
			// maestros.children.push({ path: '/stock/ubicaciones', title: 'Ubicaciones', type: 'link' });
		}

		this.MENUITEMS1.push(maestros);

		const ano = '';
		this.MENUITEMS1.push({ headTitle1: 'CLIENT EXPERIENCE', headTitle2: 'Copyright 2021 - ' + new Date().getFullYear() + ' © ENVIOS' });

		this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS1);
	}
	// ********************************************************************************************************

	// *********************************************** OPERARIO ***********************************************

	async crearMenuOperario() {

		this.MENUITEMSOP = [];

		const data = {
			_id: this.empresa
		}

		await this.services.getCompany(data).then(res => {
			this.esCart = res[0].cart !== undefined ? res[0].cart : false;
			this.esStock = res[0].stock !== undefined ? res[0].stock : false;
		});

		//****************** PEDIDOS ****************************** */

		if (this.userRol.crear_pedido || this.userRol.consulta_pedidos) {

			const pedidos = {
				title: 'Pedidos', icon: 'tag', type: 'sub', badgeType: 'danger', children: [
					// { path: '/requests/analitycs', title: 'Analítica', type: 'link' }
				]
			}

			if (this.userRol.crear_pedido) {
				pedidos.children.push({ path: '/requests/create-request', title: 'Crear pedido', type: 'link' });
			}

			if (this.userRol.consulta_pedidos) {
				pedidos.children.push({ path: '/requests/browse-request', title: 'Browse by Dates', type: 'link' });
			}

			// if (this.userRol.picking) {
			// 	pedidos.children.push({ path: '/requests/picking', title: 'Picking', type: 'link' });
			// }

			// if (this.userRol.packing) {
			// 	pedidos.children.push({ path: '/requests/packing', title: 'Picking', type: 'link' });
			// }

			// if (this.userRol.dispatch) {
			// 	pedidos.children.push({ path: '/requests/dispatch', title: 'Despachos', type: 'link' });
			// }

			this.MENUITEMSOP.push(pedidos);
		}
		//******************************************************** */
		//****************** ENVIOS ****************************** */

		if (this.userRol.envios || this.userRol.consulta_envios) {

			const envios = {
				title: 'Orders', icon: 'truck', type: 'sub', badgeType: 'success', children: [
					// { path: '/orders/analitycs', title: 'Analítica', type: 'link' }
				]
			}

			if (this.userRol.envios) {
				envios.children.push({ path: '/orders/create-order', title: 'Create', type: 'link' });
			}

			if (this.userRol.consulta_envios) {
				envios.children.push({ path: '/orders/browse-order', title: 'Browse by Dates', type: 'link' });
			}

			this.MENUITEMSOP.push(envios);
		}
		//******************************************************** */
		//****************** STOCK Y MAESTROS ******************** */

		if (this.esStock && this.userRol.stock) {
			const stock = {
				title: 'Stock', icon: 'package', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', children: [
					// { path: '/stock/analitycs', title: 'Analítica', type: 'link' },
					{ path: '/stock/inventory', title: 'Inventario', type: 'link' },
					// { path: '/stock/reception', title: 'Recepcion de Mercancia', type: 'link' },
					{ path: '/stock/adjustment', title: 'Movimientos de Productos', type: 'link' },
					{ path: '/stock/movements', title: 'Historial', type: 'link' },
					// { path: '/stock/tagsGenerator', title: 'Generador de etiquetas', type: 'link' },
					// { path: '/stock/physicalInventory', title: 'Inventario Fisico', type: 'link' },
				]
			}

			this.MENUITEMSOP.push(stock);
		}


		const maestros = {
			title: 'Masters',
			icon: 'box',
			type: 'sub',
			active: false,
			children: [
				{ path: '/maestros/edit-profile', title: 'Profile', type: 'link' },
				{ path: '/maestros/direcciones', title: 'Addresses', type: 'link' }
			]
		}

		// if (this.esCart && this.userRol.cart) {
		// 	// maestros.children.push({ path: '/cart/analitycs', title: 'Analítica Cart', type: 'link' });
		// 	maestros.children.push({ path: '/cart/productos', title: 'Products', type: 'link' });
		// 	maestros.children.push({ path: '/cart/imagenes', title: 'Products images', type: 'link' });
		// }

		if (this.esStock && this.userRol.stock) {
			maestros.children.push({ path: '/cart/productos', title: 'Products', type: 'link' });
			maestros.children.push({ path: '/cart/imagenes', title: 'Products images', type: 'link' });
			maestros.children.push({ path: '/stock/bodegas', title: 'Bodegas', type: 'link' });
			maestros.children.push({ path: '/stock/ubicaciones', title: 'Ubicaciones', type: 'link' });
		}

		this.MENUITEMSOP.push(maestros);

		this.MENUITEMSOP.push({ headTitle1: 'CLIENT EXPERIENCE', headTitle2: 'Copyright 2021 - ' + new Date().getFullYear() + ' © ENVIOS' });


		this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSOP);

	}

	// ******************************************************************************************************
	// ********************************** OPERARIO PACKING **************************************************

	async crearMenuOperarioPacking() {

		this.MENUITEMSOPPACK = [];

		const data = {
			_id: this.empresa
		}
		await this.services.getCompany(data).then(res => {
			this.esCart = res[0].cart !== undefined ? res[0].cart : false;
			this.esStock = res[0].stock !== undefined ? res[0].stock : false;
		});

		//****************** PEDIDOS ****************************** */

		if (this.userRol?.crear_pedido || this.userRol?.consulta_pedidos) {
			const pedidos = {
				title: 'Pedidos', icon: 'tag', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', children: [
					// { path: '/requests/analitycs', title: 'Analítica', type: 'link' }
				]
			}

			if (this.userRol.crear_pedido) {
				pedidos.children.push({ path: '/requests/create-request', title: 'Crear pedido', type: 'link' });
			}

			if (this.userRol.consulta_pedidos) {
				pedidos.children.push({ path: '/requests/browse-request', title: 'Browse by Dates', type: 'link' });
			}

			// if (this.userRol.picking) {
			// 	pedidos.children.push({ path: '/requests/picking', title: 'Picking', type: 'link' });
			// }

			// if (this.userRol.packing) {
			// 	pedidos.children.push({ path: '/requests/packing', title: 'Packing', type: 'link' });
			// }

			// if (this.userRol.dispatch) {
			// 	pedidos.children.push({ path: '/requests/dispatch', title: 'Despachos', type: 'link' });
			// }

			this.MENUITEMSOPPACK.push(pedidos)

		}

		//******************************************************** */
		//****************** ENVIOS ****************************** */

		if (await this.userRol.envio || await this.userRol.consulta_envios) {

			const envios = {
				title: 'Orders', icon: 'truck', type: 'sub', badgeType: 'success', children: [
					// { path: '/orders/analitycs', title: 'Analítica', type: 'link' }
				]
			}

			if (await this.userRol.envios) {
				envios.children.push({ path: '/orders/create-order', title: 'Create', type: 'link' });
			}

			if (await this.userRol.consulta_envios) {
				envios.children.push({ path: '/orders/browse-order', title: 'Browse by Dates', type: 'link' });
			}

			this.MENUITEMSOPPACK.push(envios);
		}
		//******************************************************** */
		//****************** STOCK Y MAESTROS ******************** */

		if (this.esStock && this.userRol.stock) {
			const stock = {
				title: 'Stock', icon: 'package', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', children: [
					// { path: '/stock/analitycs', title: 'Analítica', type: 'link' },
					{ path: '/stock/inventory', title: 'Inventario', type: 'link' },
					// { path: '/stock/reception', title: 'Recepcion de Mercancia', type: 'link' },
					{ path: '/stock/adjustment', title: 'Movimientos de Productos', type: 'link' },
					{ path: '/stock/movements', title: 'Historial', type: 'link' },
					// { path: '/stock/tagsGenerator', title: 'Generador de etiquetas', type: 'link' },
					// { path: '/stock/physicalInventory', title: 'Inventario Fisico', type: 'link' },
				]
			}
			this.MENUITEMSOPPACK.push(stock);
		}

		const maestros = {
			title: 'Masters',
			icon: 'box',
			type: 'sub',
			active: false,
			children: [
				{ path: '/maestros/edit-profile', title: 'Profile', type: 'link' },
				{ path: '/maestros/direcciones', title: 'Addresses', type: 'link' }
			]
		}

		// if (this.esCart && this.userRol.cart) {
		// 	// maestros.children.push({ path: '/cart/analitycs', title: 'Analítica Cart', type: 'link' });
		// 	maestros.children.push({ path: '/cart/productos', title: 'Products', type: 'link' });
		// 	maestros.children.push({ path: '/cart/imagenes', title: 'Products images', type: 'link' });
		// }

		if (this.esStock && this.userRol.stock) {
			maestros.children.push({ path: '/cart/productos', title: 'Products', type: 'link' });
			maestros.children.push({ path: '/cart/imagenes', title: 'Products images', type: 'link' });
			maestros.children.push({ path: '/stock/bodegas', title: 'Bodegas', type: 'link' });
			maestros.children.push({ path: '/stock/ubicaciones', title: 'Ubicaciones', type: 'link' })
		}

		this.MENUITEMSOPPACK.push(maestros)

		this.MENUITEMSOPPACK.push({ headTitle1: 'CLIENT EXPERIENCE', headTitle2: 'Copyright 2021 - ' + new Date().getFullYear() + ' © ENVIOS' })


		this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSOPPACK);

	}

	// ******************************************************************************************************
	// ********************************** USUARIO !== MOVEE SIN ROL**************************************************

	async crearMenuNoRol() {

		this.MENUITEMSNOROL = [];

		const data = {
			_id: this.empresa
		}
		await this.services.getCompany(data).then(res => {
			this.esCart = res[0].cart !== undefined ? res[0].cart : false;
			this.esStock = res[0].stock !== undefined ? res[0].stock : false;
		});

		//****************** PEDIDOS ****************************** */

		// if (this.userRol.crear_pedido) {
		// 	const pedidos = {
		// 		title: 'Pedidos', icon: 'tag', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', children: [
		// 			{ path: '/requests/create-request', title: 'Crear pedido', type: 'link' }
		// 		]
		// 	}

		// 	if (this.userRol.consulta_pedidos) {
		// 		pedidos.children.push({ path: '/requests/browse-request', title: 'Browse by Dates', type: 'link' });
		// 	}

		// 	if (this.userRol.picking) {
		// 		pedidos.children.push({ path: '/requests/picking', title: 'Picking', type: 'link' });
		// 	}

		// 	if (this.userRol.packing) {
		// 		pedidos.children.push({ path: '/requests/packing', title: 'Picking', type: 'link' });
		// 	}

		// 	if (this.userRol.dispatch) {
		// 		pedidos.children.push({ path: '/requests/dispatch', title: 'Despachos', type: 'link' });
		// 	}

		// 	this.MENUITEMSOPPACK.push(pedidos)
		// }
		//******************************************************** */
		//****************** ENVIOS ****************************** */


		const envios = {
			title: 'Orders', icon: 'truck', type: 'sub', badgeType: 'success', children: [
				// { path: '/orders/analitycs', title: 'Analítica', type: 'link',  },
				{ path: '/orders/create-order', title: 'Create', type: 'link', },
				{ path: '/orders/browse-order', title: 'Browse by Dates', type: 'link' }
			]
		}

		this.MENUITEMSNOROL.push(envios);
		//******************************************************** */
		//****************** STOCK Y MAESTROS ******************** */

		if (this.esStock) {
			const stock = {
				title: 'Stock', icon: 'package', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', children: [
					// { path: '/stock/analitycs', title: 'Analítica', type: 'link' },
					{ path: '/stock/inventory', title: 'Inventario', type: 'link' },
					// { path: '/stock/reception', title: 'Recepcion de Mercancia', type: 'link' },
					{ path: '/stock/adjustment', title: 'Movimientos de Productos', type: 'link' },
					{ path: '/stock/movements', title: 'Historial', type: 'link' },
					// { path: '/stock/tagsGenerator', title: 'Generador de etiquetas', type: 'link' },
					// { path: '/stock/physicalInventory', title: 'Inventario Fisico', type: 'link' },
				]
			}
			this.MENUITEMSNOROL.push(stock);
		}

		const maestros = {
			title: 'Masters',
			icon: 'box',
			type: 'sub',
			active: false,
			children: [
				{ path: '/maestros/edit-profile', title: 'Profile', type: 'link' },
				{ path: '/maestros/direcciones', title: 'Addresses', type: 'link' }
			]
		}

		// if (this.esCart) {
		// 	// maestros.children.push({ path: '/cart/analitycs', title: 'Analítica Cart', type: 'link' });
		// 	maestros.children.push({ path: '/cart/productos', title: 'Products', type: 'link' });
		// 	maestros.children.push({ path: '/cart/imagenes', title: 'Products images', type: 'link' });
		// }

		if (this.esStock) {
			maestros.children.push({ path: '/cart/productos', title: 'Products', type: 'link' });
			maestros.children.push({ path: '/cart/imagenes', title: 'Products images', type: 'link' });
			maestros.children.push({ path: '/stock/bodegas', title: 'Bodegas', type: 'link' });
			maestros.children.push({ path: '/stock/ubicaciones', title: 'Ubicaciones', type: 'link' })
		}

		this.MENUITEMSNOROL.push(maestros)

		this.MENUITEMSNOROL.push({ headTitle1: 'CLIENT EXPERIENCE', headTitle2: 'Copyright 2021 - ' + new Date().getFullYear() + ' © ENVIOS' })


		this.items = new BehaviorSubject<Menu[]>(this.MENUITEMSNOROL);

	}

	// ******************************************************************************************************

	//**************************************** MOVEE ********************************************************
	MENUITEMSA: Menu[] = [
		// {
		// 	headTitle1: 'SHIPPING', headTitle2: 'Orders maintenance',
		// },
		{
			title: 'Pedidos', icon: 'tag', type: 'sub', badgeType: 'danger', children: [
				// { path: '/requests/analitycs', title: 'Analítica', type: 'link' },
				{ path: '/requests/create-request', title: 'Crear pedido', type: 'link' },
				{ path: '/requests/browse-request', title: 'Browse by Dates', type: 'link' },
				{ path: '/requests/picking', title: 'Picking', type: 'link' },
				{ path: '/requests/packing', title: 'Packing', type: 'link' },
				{ path: '/requests/dispatch', title: 'Despachos', type: 'link' },
				// { path: '/requests/entregas', title: 'Entregas', type: 'link' },
				// { path: '/map/geoloc', title: 'Geoloc', type: 'link' },
				// { path: '/map/google', title: 'Google', type: 'link' },
				// { path: '/map/leaflet', title: 'LeafLet', type: 'link' },
				// { path: '/requests/diasiniva', title: 'Día sin IVA', type: 'link' }
			]
		},
		{
			title: 'Orders', icon: 'truck', type: 'sub', badgeType: 'success', children: [
				// { path: '/orders/analitycs', title: 'Analítica', type: 'link' },
				{ path: '/orders/create-order', title: 'Create', type: 'link' },
				// { path: '/orders/quotation', title: 'Cotización', type: 'link' },
				{ path: '/orders/browse-order', title: 'Browse by Dates', type: 'link' },
				{ path: '/orders/guides', title: 'Guías', type: 'link' }

			]
		},
		{
			title: 'Billing', icon: 'edit', type: 'sub', active: false, children: [
				{ path: '/invoice/pay-order', title: 'Facturación envíos', type: 'link' },
				{ path: '/invoice/invoicing', title: 'Facturación total', type: 'link' },
				{ path: '/invoice/co2total', title: 'Consulta CO2', type: 'link' }
			]
		},
		// {
		// 	headTitle1: 'MOVEE CART', headTitle2: 'MOVEE Shopping Cart',
		// },
		// {
		// 	headTitle1: 'MOVEE STOCK', headTitle2: 'Movee Stock',
		// },
		{
			// title: 'Stock', icon: 'package', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', children: [
			title: 'Stock', icon: 'package', type: 'sub', badgeType: 'danger', children: [
				// { path: '/stock/analitycs', title: 'Analítica', type: 'link' },
				{ path: '/stock/pdi', title: 'PDI', type: 'link' },
				{ path: '/stock/inventory', title: 'Inventario', type: 'link' },
				{ path: '/stock/backOrder', title: 'Back Order', type: 'link' },
				{ path: '/stock/moveWarehouses', title: 'Movimientos entre bodegas', type: 'link' },
				{ path: '/stock/reception', title: 'Recepcion de Mercancia', type: 'link' },
				{ path: '/stock/adjustment', title: 'Ajustes de Productos', type: 'link' },
				{ path: '/stock/movements', title: 'Historial', type: 'link' },
				{ path: '/stock/tagsGenerator', title: 'Generador de Etiquetas', type: 'link' },
				{ path: '/stock/physicalInventory', title: 'Inventario Fisico', type: 'link' },
				{ path: '/stock/bodegas', title: 'Bodegas', type: 'link' },
				{ path: '/stock/ubicaciones', title: 'Ubicaciones', type: 'link' }
				// 		// { path: '/stock/kardex', title: 'KARDEX', type: 'link' },
				// 		// { path: '/stock/compras', title: 'Compras', type: 'link' },
				// 		// { path: '/stock/ventas', title: 'Ventas', type: 'link' },
			]
		},
		// {
		// 	headTitle1: 'UTILITIES', headTitle2: 'Settings and Masters',
		// },
		{
			title: 'Masters', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/maestros/edit-profile', title: 'Profile', type: 'link' },
				{ path: '/maestros/empresas', title: 'Companies', type: 'link' },
				// { path: '/security/roles', title: 'Roles', type: 'link' },
				{ path: '/maestros/usuarios', title: 'Users', type: 'link' },
				{ path: '/maestros/direcciones', title: 'Addresses', type: 'link' },
				{ path: '/maestros/ajustes', title: 'Settings', type: 'link' },
				{ path: '/maestros/cobertura', title: 'Coverage', type: 'link' },
				// 	]
				// },
				// {
				// 	title: 'Cart Masters', icon: 'shopping-cart', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', active: false, children: [
				// { path: '/cart/analitycs', title: 'Analítica Cart', type: 'link' },
				{ path: '/cart/productos', title: 'Products', type: 'link' },
				{ path: '/cart/imagenes', title: 'Products images', type: 'link' },
				{ path: '/cart/listaPrecios', title: 'Lista de precios', type: 'link' }
				
			]
		},
		// {
		// 	title: 'Maestros Stock', icon: 'shopping-cart', type: 'sub', badgeType: 'danger', badgeValue: 'Nuevo', active: false, children: [
		// 		{ path: '/stock/bodegas', title: 'Bodegas', type: 'link' },
		// 		{ path: '/stock/ubicaciones', title: 'Ubicaciones', type: 'link' },
		// 	]
		// },
		{
			headTitle1: 'CLIENT EXPERIENCE', headTitle2: 'Copyright 2021 - ' + new Date().getFullYear() + ' © ENVIOS',
		},
	];
	//*************************************************************************************************************

	searchInMenu(str, strArray) {
		for (var j = 0; j < strArray.length; j++) {
			if (strArray[j].name.match(str)) return j;
			for (var k = 0; k < strArray[j].submenu.length; k++) {
				if (strArray[j].submenu[k].name.match(str)) return j;
			}
		}
		return -1;
	}

	// MEGAMENUITEMS: Menu[] = [
	// 	{
	// 		title: 'Error Pages', type: 'sub', active: true, children: [
	// 			{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
	// 		]
	// 	},
	// 	{
	// 		title: 'Authentication', type: 'sub', active: false, children: [
	// 			{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Login BG Image', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Login BG Video', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Simple Register', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Register BG Image', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Register BG Video', type: 'extLink' }
	// 		]
	// 	},
	// 	{
	// 		title: 'Usefull Pages', type: 'sub', active: false, children: [
	// 			{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
	// 		]
	// 	},
	// 	{
	// 		title: 'Email templates', type: 'sub', active: false, children: [
	// 			{ path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
	// 			{ path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
	// 			{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
	// 			{ path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
	// 			{ path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
	// 			{ path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
	// 		]
	// 	},
	// 	{
	// 		title: 'Coming Soon', type: 'sub', active: false, children: [
	// 			{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
	// 		]
	// 	},
	// ];

	// LEVELMENUITEMS: Menu[] = [
	// 	{
	// 		path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
	// 	},
	// 	{
	// 		title: 'Users', icon: 'users', type: 'sub', active: false, children: [
	// 			{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
	// 			{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
	// 		]
	// 	},
	// 	{ path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
	// 	{ path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
	// 	{ path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	// ];

	// Array
	items; //this.empresa === '1' ? new BehaviorSubject<Menu[]>(this.MENUITEMSA) : new BehaviorSubject<Menu[]>(this.MENUITEMS);
	// megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	// levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
