import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { ServiciosService } from 'src/app/shared/services/servicios.service';

@Component({
  selector: 'app-ajustes',
  templateUrl: './ajustes.component.html',
  styleUrls: ['./ajustes.component.scss']
})
export class AjustesComponent implements OnInit {
  @ViewChild('AM') AM: ElementRef;
  @ViewChild('PM') PM: ElementRef;
  @ViewChild('top') top: ElementRef;
  @ViewChild('left') left: ElementRef;
  @ViewChild('right') right: ElementRef;
  @ViewChild('bottom') bottom: ElementRef;
  @ViewChild('width') width: ElementRef;
  @ViewChild('height') height: ElementRef;
  @ViewChild('consecutivo1') consecutivo1: ElementRef;
  @ViewChild('consecutivo2') consecutivo2: ElementRef;

  public empre = '';

  task: string;
  tasks = [];
  cargando = false;

  bloqueados = [];
  permitidos = [];
  notificaci = [];
  notas = [];
  medidas = [];

  dateBlock: string;
  dateAllow: string;
  dateNotif: string;
  textNotif: string;
  titleNota: string;
  textNota: string;

  constructor(
    private service: ServiciosService
  ) {

    this.service.getSettings().then(async res => {
      this.AM.nativeElement.value = await res[0].AM;
      this.PM.nativeElement.value = await res[0].PM;
      this.bloqueados = await res[0].bloqueados || [];
      this.permitidos = await res[0].permitidos || [];
      this.notificaci = await res[0].notificaci || [];
      this.notas = await res[0].notas || [];
      this.consecutivo1.nativeElement.value = await res[0].consecutivo1;
      this.consecutivo2.nativeElement.value = await res[0].consecutivo2;
      this.top.nativeElement.value = await res[0].medidas[0].superior || [];
      this.bottom.nativeElement.value = await res[0].medidas[0].inferior || [];
      this.left.nativeElement.value = await res[0].medidas[0].izquierda || [];
      this.right.nativeElement.value = await res[0].medidas[0].derecha || [];
      this.width.nativeElement.value = await res[0].medidas[0].ancho || [];
      this.height.nativeElement.value = await res[0].medidas[0].largo || [];

    });
  }

  ngOnInit(): void {

    this.cargando = true;

    const res: any = JSON.parse(localStorage.getItem('userMce'));
    const jsonit = {
      "_id": res.nit
    }
    this.service.getCompany(jsonit).then(e => {
      this.empre = e[0].nombre;
      this.cargando = false;
    }).catch(err => {
      this.cargando = false;
    })
  }

  onClick() {
    this.tasks.push({ name: this.task });
    this.task = '';
  }

  save() {

    this.cargando = true;

    const datos = {
      AM: this.AM.nativeElement.value,
      PM: this.PM.nativeElement.value,
      bloqueados: this.bloqueados,
      permitidos: this.permitidos,
      notificaci: this.notificaci,
      notas: this.notas,
      consecutivo1: this.consecutivo1,
      consecutivo2: this.consecutivo2
    }

    this.service.editSettings(datos).then(async res => {
      if (res.status !== undefined) {
        Swal.fire({
          icon: 'error',
          title: '¡ No se grabò la información !',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        // this.activeModal.close('Salvado');
        // await this.service.publishNofications({ ev: 'not' });

      }

      this.cargando = false;

    });
  }

  addDateBlock(date: string) {
    const id = this.bloqueados.findIndex(x => x.fecha === date);
    if (id === -1)
      this.bloqueados.push({ fecha: date });
  }

  removeDateBlock(item: any) {
    const id = this.bloqueados.findIndex(x => x.fecha === item.fecha);
    if (id !== -1)
      this.bloqueados.splice(id, 1);
  }

  addDateAllow(date: string) {
    const id = this.permitidos.findIndex(x => x.fecha === date);
    if (id === -1)
      this.permitidos.push({ fecha: date });

  }

  removeDateAllow(item: any) {
    const id = this.permitidos.findIndex(x => x.fecha === item.fecha);
    if (id !== -1)
      this.permitidos.splice(id, 1);
  }

  addDateNotif(date: string, text: string) {
    const id = this.notificaci.findIndex(x => x.fecha === date);
    if (id === -1)
      this.notificaci.push({ fecha: date, texto: text });
  }

  removeDateNotif(item: any) {
    const id = this.notificaci.findIndex(x => x.fecha === item.fecha);
    if (id !== -1)
      this.notificaci.splice(id, 1);
  }

  addNota(title: string, text: string) {
    const id = this.notas.findIndex(x => x.titulo === title);
    if (id === -1)
      this.notas.push({ titulo: title, texto: text });
  }

  removeNota(item: any) {
    const id = this.notas.findIndex(x => x.titulo === item.titulo);
    if (id !== -1)
      this.notas.splice(id, 1);
  }

  pageSizes() {
    
    this.cargando = true;

    this.medidas = [{
      superior: Number(this.top.nativeElement.value),
      inferior: Number(this.bottom.nativeElement.value), 
      derecha: Number(this.right.nativeElement.value), 
      izquierda: Number(this.left.nativeElement.value),
      ancho: Number(this.width.nativeElement.value),
      largo: Number(this.height.nativeElement.value)
    }];
    
    const datos = {
      AM: this.AM.nativeElement.value,
      PM: this.PM.nativeElement.value,
      bloqueados: this.bloqueados,
      permitidos: this.permitidos,
      notificaci: this.notificaci,
      notas: this.notas,
      medidas: this.medidas
    }
    
    this.service.editSettings(datos).then(async res => {
      if (res.status !== undefined) {

        Swal.fire({
          icon: 'error',
          title: '¡ No se grabò la información !',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        // this.activeModal.close('Salvado');
        // await this.service.publishNofications({ ev: 'not' });
      }

      this.cargando = false;

    });
  }


}
