import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'default',
    loadChildren: () => import('../../components/default/default.module').then(m => m.DefaultModule)
  },
  {
    path: 'map',
    loadChildren: () => import('../../components/map/map.module').then(m => m.MapModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule)
  },
  {
    path: 'requests',
    loadChildren: () => import('../../components/requests/requests.module').then(m => m.RequestsModule)
  },
  {
    path: 'maestros',
    loadChildren: () => import('../../components/maestros/maestros.module').then(m => m.MaestrosModule)
  },
  {
    path: 'mapas',
    loadChildren: () => import('../../components/map/map.module').then(m => m.MapModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('../../components/cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'stock',
    loadChildren: () => import('../../components/stock/stock.module').then(m => m.StockModule)
  },
  {
    path: 'security',
    loadChildren: () => import('../../components/security/security.module').then(m => m.SecurityModule)
  },

];
