import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiciosService } from 'src/app/shared/services/servicios.service';
import { NavService, Menu } from '../../../../services/nav.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;
  lang = 'es';

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'co'
  }]

  idUsuario = '';

  public selectedLanguage: any = {
    language: 'Español',
    code: 'es',
    type: 'CO',
    icon: 'es'
  }

  constructor(
    private translate: TranslateService,
    public navServices: NavService,
    private services: ServiciosService) { }

  ngOnInit() {
    const res: any = JSON.parse(localStorage.getItem('userMce'));
    this.idUsuario = res.email;
    if (res.lang !== undefined) {
      const id = this.languages.findIndex(x => x.code === res.lang)
      if (id !== -1) {
        this.changeLanguage(this.languages[id]);
      }
    }

  }

  changeLanguage(lang) {

    // debugger;
    this.translate.setDefaultLang(this.lang);
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
    this.guardarLenguaje(lang.code);
  }

  async guardarLenguaje(lang) {
    const item = {
      email: this.idUsuario,
      emailAnt: this.idUsuario,
      idioma: lang,
      settings: '1'
    }

    await this.services.editUsuario(item).then(res => {

      const res2: any = JSON.parse(localStorage.getItem('userMce'));
      res2.lang = lang;
      localStorage.setItem('userMce', JSON.stringify(res2));

    });
  }


}
