import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthService } from './firebase/auth.service';
import 'rxjs/add/observable/fromPromise';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { ServiciosService } from './servicios.service';
import { environment } from '../../../environments/environment.prod';

@Injectable()
export class InterInterceptor implements HttpInterceptor {


  constructor(
    private service: ServiciosService,
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    if (request.url.includes('movee') || request.url.includes('localhost')) {
      return this.handleAccess(request, next)
        .pipe(
          catchError((err: Response) => {
            if ([401, 403].indexOf(err.status) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
              this.service.signOut();
              this.router.navigate(['/login']);
            }
            return throwError(err);
          }));
    } else {
      return next.handle(request)
        .pipe(
          catchError((err: Response) => {
            if ([401, 403].indexOf(err.status) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
              this.service.signOut();
              this.router.navigate(['/login']);
            }
            return throwError(err);
          }));
    }

  }

  // private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
  private handleAccess(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const dato = localStorage.getItem('userMce')

    // const token = await this.service.getToken();
    if (dato !== null) {
      const user = JSON.parse(dato);
      const token = user.token;

      // await this.loginService.getCedula();

      // const numero = await this.loginService.getNumero();
      // const ter = numero.substr(numero.length - 4);
      // const term = this.service.doEncrypt(ter);

      // headers.append('Content-Type', 'application/json');
      // headers.append('Accept', 'application/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
      // headers.append('Origin','http://localhost:3000');


      const newReq = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
          'user': user.nit,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'email': user.email,
          'Access-Control-Allow-Origin': environment.urlPermitidas
        }
      });
      return next.handle(newReq);

    }
    else {
      const newReq = request.clone({
        setHeaders: {
          // user: user,
          // terminal: term
        }
      });
      return next.handle(newReq);
    }
  }

}