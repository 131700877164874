<div [ngClass]="{'notification-box-animated':qty>0, 'notification-box':qty==0}" (click)="toggleNotificationMobile()">
  <app-feather-icons style="height:50px;width:50px" [icon]="'bell'"></app-feather-icons>
  <span class="badge badge-pill badge-danger" *ngIf="qty>0">{{qty}}</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
  <li>
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <h6 class="f-18 mb-0">Notificaciones</h6>
  </li>
  <li *ngFor="let item of items">
    <p style="text-align: left;">
      <i class="fa fa-circle-o mr-3 font-danger"></i><strong>{{item.fecha}}</strong></p>
    <p style="text-align: left;">{{item.texto}}
      <!-- <span class="pull-right m-2 ">{{item.fecha}}</span> -->
    </p>
  </li>
  <!-- <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-primary"></i>Delivery processing <span class="pull-right">10 min.</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-success"></i>Order Complete<span class="pull-right">1 hr</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-info"></i>Tickets Generated<span class="pull-right">3 hr</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-danger"></i>Delivery Complete<span class="pull-right">6 hr</span>
    </p>
  </li>
  <li>
    <a class="btn btn-primary" href="#">Check all notification</a>
  </li> -->
</ul>
