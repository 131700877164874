export const environment = {
  production: true,
  API_URL: 'https://api.movee.co/',
  urlPermitidas: 'https://pce.movee.co'
};

export const firebaseConfig = {
  apiKey: "AIzaSyARSQrcOb7j_2ZnkPyRNXxqOi4LbPQhcDQ",
  authDomain: "pcetransmovee.firebaseapp.com",
  projectId: "apitransmovee",
  storageBucket: "apitransmovee.appspot.com",
  messagingSenderId: "256046627104",
  appId: "1:256046627104:web:751be86ad50b5a96feff45",
  measurementId: "G-L6X1PSLC8T"
}
