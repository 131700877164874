import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor() {
    }

    toISO8601(date) {
        let day = '' + date.getDate()
        let month = '' + (date.getMonth() + 1)
        const year = date.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
    }

    startsWith(str, prefix) {
        return str ? str.indexOf(prefix) === 0 : false
    }

    endsWith(str, suffix) { return str ? str.indexOf(suffix, str.length - suffix.length) !== -1 : false }

    contains(str, element) { return str ? str.includes(element) : false }

    containsEscaped(str, element) {
        return this.contains(str, element.replace(/"/g, '\\"'))
    }

    containsOrEscaped(str, element) {
        return this.contains(str, element) || this.containsEscaped(str, element)
    }

    hash(data) {
        const res =  crypto.SHA256(data);
        return res.toString(crypto.enc.Base64)
    }

}