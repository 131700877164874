import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import myJson from './../../../assets/json/ciudades.json';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  notificaci = [];
  private eventNotif = new Subject<any>();
  private subject = new Subject<any>();
  subject$ = this.subject.asObservable();

  url = environment.API_URL;

  version = 'Versión 0.23.1102';

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  async getOrdenUnica(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/get', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getOrdenCalendar(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/all', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getOrdenCO2(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/co2emmit', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }
  async validateAddress(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/validate', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** USUARIOS *******************************************

  async getUsuarios(datos: any) {
    return this.httpClient.get(this.url + 'v1/users/all', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getUsuario(datos: any) {
    return this.httpClient.get(this.url + 'v1/users', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async createUsuario(datos: any) {
    return this.httpClient.post(this.url + 'v1/users/create', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async editUsuario(datos: any) {
    return this.httpClient.post(this.url + 'v1/users/edit', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async editPass(datos: any) {
    return this.httpClient.post(this.url + 'v1/users/editPass', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // async restorePass(datos: any) {
  //   return this.httpClient.post(this.url + 'v1/users/restorePass', datos)
  //     .toPromise()
  //     .then(async (res) => {
  //       return res;
  //     }).catch(err => {
  //       return err;
  //     });
  // }

  // ***************************************** EMPRESAS *******************************************

  async getCompanies() {
    return this.httpClient.get(this.url + 'v1/companies/all')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getActiveCompanies() {
    return this.httpClient.get(this.url + 'v1/companies/active')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getCompany(datos: any) {
    return this.httpClient.post(this.url + 'v1/companies', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async createCompany(datos: any) {

    return this.httpClient.post(this.url + 'v1/companies/create', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async editCompany(datos: any) {
    return this.httpClient.post(this.url + 'v1/companies/edit', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async logoCompany(datos: any) {
    return this.httpClient.post(this.url + 'v1/companies/editLogo', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getCompaniesInCart() {
    return this.httpClient.get(this.url + 'v1/companies/inCart')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getCompaniesInStock() {
    return this.httpClient.get(this.url + 'v1/companies/inStock')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getCompaniesInCartInStock() {
    return this.httpClient.get(this.url + 'v1/companies/inCartInStock')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** DIRECCIONES *******************************************

  async getAddresses(datos: any) {
    return this.httpClient.post(this.url + 'v1/addresses/all', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getAddres(datos: any) {
    return this.httpClient.post(this.url + 'v1/addresses', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async createAddress(datos: any) {

    return this.httpClient.post(this.url + 'v1/addresses/create', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  editAddress(datos: any) {
    return this.httpClient.post(this.url + 'v1/addresses/edit', datos)
      .toPromise()
      .then((res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async deleteAddress(datos: any) {
    return this.httpClient.post(this.url + 'v1/addresses/delete', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ************************************** LOGIN

  signInWithEmailAndPassword(datos) {
    return this.httpClient.post(this.url + 'v1/authentication', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // Sign out
  signOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    // return this.afAuth.auth.signOut().then(() => {
    // this.showLoader = false;
    localStorage.clear();
    // this.cookieService.deleteAll('user', '/auth/login');
    this.router.navigate(['/login']);
    // });
  }


  // ***************************************** ORDENES *******************************************

  postOrder(datos: any) {
    return this.httpClient.post(this.url + "v1/orders/order", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  editOrder(datos: any) {
    return this.httpClient.post(this.url + "v1/orders/edit", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  deleteOrder(datos: any) {
    return this.httpClient.post(this.url + "v1/orders/delete", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  getDashboard(datos: any) {
    return this.httpClient.post(this.url + "v1/orders/dashboard", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  getTotalProductos(nit: any) {
    return this.httpClient.get(this.url + "v1/products/total/" + nit)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  getFacturacion(datos: any) {
    return this.httpClient.post(this.url + "v1/orders/factura", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getFacturacionEnviosMesCliente(datos: any) {
    return this.httpClient.post(this.url + "v1/kpis/getFacturacionPedidosMesCliente", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getUbicacionesDiaNit(datos: any) {
    return this.httpClient.post(this.url + "v1/kpis/getLocationsDayNit", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getUbicacionesDiaAll(datos: any) {
    return this.httpClient.post(this.url + "v1/kpis/getLocationsDay", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  orderHistory(datos: any) {
    return this.httpClient.post(this.url + "v1/orders/history", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  ordersCoords(datos: any) {
    return this.httpClient.post(this.url + "v1/orders/coords", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }
  // ***************************************** AJUSTES *******************************************

  async getSettings() {
    return this.httpClient.get(this.url + 'v1/settings')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  editSettings(datos: any) {
    return this.httpClient.post(this.url + "v1/settings/edit", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  readCityFile() {
    return myJson;
  }

  // public publishNofications(data: any) {
  //   this.eventNotif.next(data);
  // }

  // public getNofications(): Subject<any> {
  //   return this.eventNotif;
  // }



  // ***************************************** PRODUCTS *******************************************


  postProduct(datos: any) {
    return this.httpClient.post(this.url + "v1/products/create", datos).toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      })
  }

  async getProduct(codbar: any) {
    return this.httpClient.post(this.url + "v1/products", codbar).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getProductInventory(datos: any) {
    return this.httpClient.post(this.url + "v1/products/productInventory", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getProducsByCompany(nit: any) {
    return this.httpClient.post(this.url + "v1/products/byCompany", nit).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      })
  }

  async getProducsByClient(nit: any) {
    return this.httpClient.post(this.url + "v1/products/byClient", nit).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      })
  }

  editProduct(datos: any) {
    return this.httpClient.post(this.url + "v1/products/edit", datos).toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  deleteProduct(datos: any) {
    return this.httpClient.post(this.url + "v1/products/delete", datos).toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getAllProducts() {
    return this.httpClient.get(this.url + "v1/products/all").toPromise()
      .then(async (res) => {
        return res
      }).catch(err => {
        return err;
      })
  }

  async getColorsByCompany(datos) {
    return this.httpClient.post(this.url + "v1/products/colorByCom", datos).toPromise()
      .then(async (res) => {
        return res
      }).catch(err => {
        return err;
      })
  }

  // ***************************************** CATEGORIAS *******************************************

  async getCategories(datos) {
    return this.httpClient.post(this.url + "v1/category", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  postCategory(datos: any) {
    return this.httpClient.post(this.url + "v1/category/create", datos).toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getAllCategories() {
    return this.httpClient.get(this.url + "v1/category/all").toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  deleteCategory(datos: any) {
    return this.httpClient.post(this.url + 'v1/category/delete', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** CART *******************************************

  async getRequestCalendar(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/all', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getRequestPending(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/pending', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getStateRequest(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/getState', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getStateRequestTotal(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/getStateTotal', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }


  async getTypeRequest(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/getType', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async editStateRequest(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/state', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async crearPedido(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/new', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async editarPedido(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/edit', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getPedido(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/get', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getTotalsPedido() {
    return this.httpClient.get(this.url + 'v1/requests/total')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getCoordsRequests(data: any) {
    return this.httpClient.post(this.url + 'v1/requests/coords', data)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async emailPago(datos: any, datos2: any) {
    return this.httpClient.post(this.url + 'v1/requests/emailPago', { datos, datos2 })
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async emailPicking(datos: any, datos2: any) {
    return this.httpClient.post(this.url + 'v1/requests/emailPicking', { datos, datos2 })
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async emailDispatch(datos: any, datos2: any) {
    return this.httpClient.post(this.url + 'v1/requests/emailDispatch', { datos, datos2 })
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async selectedRequestUpdate(data: any) {
    return this.httpClient.post(this.url + 'v1/requests/selectedRequestUpdate', data)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getRequestCodbar(data: any) {
    return this.httpClient.post(this.url + 'v1/requests/getRequestCodbar', data)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getOrden(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/get', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getOrdenCliente(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/getCliente', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async requestNote(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/requestNote', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async readRequestNote() {
    return this.httpClient.get(this.url + 'v1/requests/readRequestNote')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async delRequestNote(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/delRequestNote', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async crearNota(datos: any) {
    return this.httpClient.post(this.url + 'v1/requests/createNote', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** STOCK *******************************************
  async createWarehouse(datos: any) {
    return this.httpClient.post(this.url + 'v1/warehouses/create', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getAllWarehouses() {
    return this.httpClient.get(this.url + "v1/warehouses/all").toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getWarehouse(id: any) {
    return this.httpClient.post(this.url + 'v1/warehouses/', id)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  getWarehouseByCompany(nit: any) {
    return this.httpClient.post(this.url + 'v1/warehouses/byCompany', nit)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  editWarehouse(datos: any) {
    return this.httpClient.post(this.url + "v1/warehouses/edit", datos).toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  deleteWarehouse(id: any) {
    return this.httpClient.post(this.url + 'v1/warehouses/delete', id)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async createLocation(datos: any) {
    return this.httpClient.post(this.url + 'v1/locations/create', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        8
        return err;
      });
  }

  getLocationByWarehouseCompany(data: any) {
    return this.httpClient.post(this.url + 'v1/locations/byCompanyWarehouse', data)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  guardarUbicacion(datos: any) {
    return this.httpClient.post(this.url + "v1/inventory/editLocation", datos).toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getAllLocations() {
    return this.httpClient.get(this.url + "v1/locations/all").toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  deleteLocation(id: any) {
    return this.httpClient.post(this.url + 'v1/locations/delete', id)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getQuantityTransactions(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/quantityTransactions', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async transactionsByDoc(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/transactionsByDoc', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      })
      .catch(err => {
        return err;
      })
  }

  async getReceptions(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/receptions', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getAllReceptions(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/allReceptions', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getReceptionsAndEntries(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/receptionsAndEntries', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getAllOutbounds(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/allOutbounds', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async repairTransaction(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/repairTrx', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** Inventarios *******************************************

  async getInventory(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }


  async createInventory(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }


  async editInventory(datos: any) {
    return this.httpClient.post(this.url + "v1/inventory/edit", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async deleteInventory(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/delete', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async productInventory(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/getProduct', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async setAdjustment(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/adjustment', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getMovements(datos: any) {
    return this.httpClient.post(this.url + 'v1/inventory/movements', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getTotalProductosInventory() {
    return this.httpClient.get(this.url + "v1/inventory/totalProducts")
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getTotalInventory(datos: any) {
    return this.httpClient.post(this.url + "v1/inventory/totalInventory", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getProductsInventoryByCompany(datos: any) {
    return this.httpClient.post(this.url + "v1/inventory/totalProductsByCompany", datos)
      .toPromise()
      .then(res => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async createPdi(datos: any) {
    return this.httpClient.post(this.url + "v1/inventory/pdi", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getPdi() {
    return this.httpClient.get(this.url + "v1/inventory/getPdi").toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getAllPdi() {
    return this.httpClient.get(this.url + "v1/inventory/getAllPdi").toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }


  async getDetalleInventario(datos) {
    return this.httpClient.post(this.url + "v1/inventory/getInventoryDetail", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getInventoryHistory(datos) {
    return this.httpClient.post(this.url + "v1/kpis/getHistory", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async tempInventario(datos) {
    return this.httpClient.post(this.url + "v1/inventory/tempInventory", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async physicalInventory(datos) {
    return this.httpClient.post(this.url + "v1/inventory/physicalInventory", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      })
  }

  async actualizacionReferenciaShopify(datos) {
    return this.httpClient.post(this.url + "v1/inventory/actualizacionReferenciaShopify", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      })
  }

  async pasarProductosAInventario(datos) {
    return this.httpClient.post(this.url + "v1/products/toInventoryWarehouse", datos).toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      })
  }

  // ***************************************** COORDINADORA *******************************************

  async getStates(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/states', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getCities(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/cities', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async setQuotation(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/quotation', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async setQuotationInter(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/quotationInter', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getTracking(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/tracking', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async setCollect(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/collect', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async generateGuide(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/guide', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async dispatch(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/seeDispatch', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async imprimirRotulo(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/printLabel', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** FEDEX *******************************************

  async fedexAuth() {
    return this.httpClient.get(this.url + 'v1/orders/fedexAuth')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async fedexGuide(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/fedexGuide', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async uploadImgFedex(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/uploadImages', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async validateAddressFedex(datos: any) {
    return this.httpClient.post(this.url + 'v1/orders/validateFedex', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async quoteFedex(datos: any, header: any) {
    console.log(header)
    return this.httpClient.post(this.url + 'v1/orders/quoteFedex', datos, { headers: new HttpHeaders({ 'fToken': header }) })
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** ROLES *******************************************

  async getRol(rol: any) {
    return this.httpClient.post(this.url + 'v1/roles/', rol)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async getRoles() {
    return this.httpClient.get(this.url + 'v1/roles/all')
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  async setRol(rol: any) {
    return this.httpClient.post(this.url + 'v1/roles/edit', rol)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** TRASLADOS *******************************************

  async getPendingMoves(data) {
    return this.httpClient.post(this.url + 'v1/inventory/getPendingMoves', data)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  // ***************************************** PRECIOS *******************************************

  async getPricesbylist(datos: any) {
    return this.httpClient.post(this.url + 'v1/prices/byList', datos)
      .toPromise()
      .then(async (res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

  editPricesbylist(datos: any) {
    return this.httpClient.post(this.url + 'v1/prices/editByList', datos)
      .toPromise()
      .then((res) => {
        return res;
      }).catch(err => {
        return err;
      });
  }

}
