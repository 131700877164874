import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ServiciosService } from './shared/services/servicios.service';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  IdleState = 'Not Started.';
  timedOut = false;
  lastPing?: Date = null;
  notificaci = [];

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private idle: Idle,
    private keepalive: Keepalive,
    private service: ServiciosService,
    private router: Router,
    private updates: SwUpdate,
    translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('es');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }

    // // TIMEOUT
    
    // idle.setIdle(5);
    // idle.setTimeout(1500);
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => {
    //   this.reset();
    // });

    // idle.onTimeout.subscribe(() => {
    //   this.timedOut = true;
    //   this.service.signOut();
    //   this.reset();
    // });

    // idle.onIdleStart.subscribe(() => { });

    // idle.onTimeoutWarning.subscribe((countdown) => {
    //   if (countdown === 12 && !this.router.url.includes('login')) {
    //     Swal.fire({
    //       icon: 'warning',
    //       title: '¡ Sesión inactiva por 15 minutos, en 10 segundos serà cerrada !',
    //       showConfirmButton: false,
    //       timer: 1500
    //     });
    //   }
    // });

    // keepalive.interval(1);

    // keepalive.onPing.subscribe(() => this.lastPing = new Date());

    // this.reset();

    // // fin TIMEOUT 


    updates.checkForUpdate().then(() => { }
      // this.updateCheckText = 'resolved';
    ).catch(err => { }
      // this.updateCheckText = rejected: ${ err.message }
    );

    updates.available.subscribe(async event => {
      // console.log('current version is', event.current);
      // console.log('available version is', event.available);
      Swal.fire({
        title: 'Nueva Versión',
        text: '¡Debes actualizar a la nueva versión!',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {

          updates.activateUpdate().then(() => document.location.reload());

        }
      })

    });

    updates.activated.subscribe(event => {
      // console.log('old version was', event.previous);
      // console.log('new version is', event.current);S
      Swal.fire({
        icon: 'success',
        title: '¡Aplicación actualizada!',
        showConfirmButton: true,
        timer: 1500
      });
    });

  }

  async ngOnInit() {
    // await this.service.publishNofications({ ev: 'not' });
  }

  reset() {
    this.idle.watch();
    this.IdleState = 'Started.';
    this.timedOut = false;
  }
}
