<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="loginpage" width="200px">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="loSginpage" width="200px">
            </a>
          </div>
          <div class="login-main">
            <!--   -->
            <form class="theme-form" [formGroup]="loginForm">
              <h4 class="text-center">ENVIOS CLIENT EXPERIENCE</h4>
              <br><br>
              <div class="form-group">
                <label class="col-form-label">Usuario</label>
                <input class="form-control" type="email" required="" placeholder="Ingrese su usuario o correo"
                  formControlName="email">
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                  class="text text-danger mt-1">
                  El usuario es requerido
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                  class="text text-danger mt-1">
                  Usuario no válido
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required=""
                  placeholder="Ingrese su contraseña">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                  class="text text-danger mt-1">
                  La contraseña es requerida
                </div>
              </div>
              <!-- <div class="">
                <p style="cursor: pointer; color: teal;" (click)="restore()">Olvidé mi contraseña</p>
              </div> -->
              <div class="form-group mt-4 mb-0">
                <p style="text-align: center;">
                  {{version}}
                </p>

                <!-- <div class="checkbox p-0">
                  <p style="position: relative !important; text-align: center;">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Recuerda la contraseña</label>
                  </p>
                </div> -->
                <!-- <p style="position: relative !important; text-align: center;">
                  <a [routerLink]="'/authentication/forgot-password'">¿Olvidó su contraseña?</a>
                </p> -->

                <!-- <button class="btn btn-primary btn-block" style="position: absolute;" [disabled]="!loginForm.valid"
                  type="submit"><span>Ingresar</span>
                </button> -->


              </div>
              <div class="form-group mt-0 mb-0">
                <button class="btn btn-primary btn-block g-recaptcha" 
                  data-sitekey="6LcmmdEaAAAAAHFxXohUYnyQtW6usQ7wm_cuIg52" data-action='submit'
                  type="submit" id="loginButton" [disabled]="!loginForm.valid" mat-raised-button color="primary"
                  (click)="login('abc')">
                  <span>Ingresar</span>
                </button>
              </div>
              <div class="form-group mb-2 mt-4" style="font-size:10px; opacity:.7; text-align:center">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </div>
              <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6>
                <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light" (click)="loginFacebook()" target="_blank">
                        <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook
                    </a>
                    <a class="btn btn-light" (click)="loginTwitter()" target="_blank">
                     <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                    </a>
                    <a class="btn btn-light" (click)="loginGoogle()" target="_blank">
                        <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google +
                    </a>
                  </div>
                </div>
                <p class="mt-4 mb-0">Don't have account?
                  <a [routerLink]="'/authentication/register/simple'" class="ml-2">Create Account</a>
                </p> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
